<template>
  <div>
    <v-dialog v-model="visible" :width="width" :content-class="className" @click:outside="closeDialog">
      <h2 class="page-title-list">{{ title }}</h2>
      <slot name="content"></slot>
      <div class="footer">
        <slot name="footer"></slot>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ContractDialog',
  props: {
    visible: Boolean,
    title: String,
    className: {
      type: String,
      default: ''
    },
    width: {
      type: [String, Number],
      default: 500,
    },
  },
  data() {
    return {};
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  background-color: #fff;
  padding: 20px;
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    button:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
