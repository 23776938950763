<template>
  <div>
    <v-dialog v-model="dialog">
      <h1 class="px-10">{{ pointType }}内訳</h1>

      <v-card class="pa-5 mt-5">
        <v-row>
          <v-col :cols="6">
            <div class="mb-2">
              <h3>{{ pointType }}内訳</h3>
            </div>
          </v-col>
        </v-row>

        <SimpleTable className="table1">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="(item, i) in headers2" :key="i" :style="{width: item.width}">
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="arrDataTable1.length > 0">
                <tr v-for="(item, index) in arrDataTable1" :key="index">
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{
                      new Date(item.grantedAt)
                        .toISOString()
                        .substr(0, 10)
                        .replace(/-/g, '/')
                    }}
                  </td>
                  <td>¥{{ item.pointPrice }}</td>
                  <td>
                    {{ item.pointRemains }}
                  </td>
                  <td>
                    {{
                      new Date(item.validFrom)
                        .toISOString()
                        .substr(0, 10)
                        .replace(/-/g, '/')
                    }}
                  </td>
                  <td>
                    {{
                      new Date(item.validThru)
                        .toISOString()
                        .substr(0, 10)
                        .replace(/-/g, '/')
                    }}
                  </td>
                  <td>
                    {{ item.remarks }}
                  </td>
                  <td>
                    <v-btn icon class="t-btn--prm" @click="show(item)">
                      <v-icon>fa fa-calendar-check</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn icon class="t-btn--prm" @click="subtract(item)">
                      <v-icon>fa fa-ban</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td class="text-center" colspan="8">
                  {{ $t('rules.noData') }}
                </td>
              </tr>
            </tbody>
          </template>
        </SimpleTable>
      </v-card>
      <v-card class="pa-5 mt-5">
        <v-row>
          <v-col :cols="6">
            <div class="mb-2">
              <h3>期限切れポイント内訳</h3>
            </div>
          </v-col>
        </v-row>

        <SimpleTable className="table2">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="(item, i) in headers1" :key="i" :style="{width: item.width}">
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="arrDataTable2.length > 0">
                <tr v-for="(item, index) in arrDataTable2" :key="index">
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{
                      new Date(item.grantedAt)
                        .toISOString()
                        .substr(0, 10)
                        .replace(/-/g, '/')
                    }}
                  </td>
                  <td>¥{{ item.pointPrice }}</td>
                  <td>
                    {{ item.pointRemains }}
                  </td>
                  <td>
                    {{
                      new Date(item.validFrom)
                        .toISOString()
                        .substr(0, 10)
                        .replace(/-/g, '/')
                    }}
                  </td>
                  <td>
                    {{
                      new Date(item.validThru)
                        .toISOString()
                        .substr(0, 10)
                        .replace(/-/g, '/')
                    }}
                  </td>
                  <td>
                    {{ item.goAhead }}
                  </td>
                  <td>
                    {{ item.fallingForward }}
                  </td>
                  <td>
                    {{ item.remarks }}
                  </td>
                  <td>
                    <v-btn icon class="t-btn--prm" @click="show(item)">
                      <v-icon>fa fa-calendar-check</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td class="text-center" colspan="10">
                  {{ $t('rules.noData') }}
                </td>
              </tr>
            </tbody>
          </template>
        </SimpleTable>
      </v-card>
      <div class="d-flex justify-end mt-5 mb-5">
        <v-btn small class="t-btn--red-dark" @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
          <span>&emsp;閉じる</span>
        </v-btn>
      </div>
    </v-dialog>

    <change-period
      :visible="showLink === 1"
      @close="showLink = -1"
    ></change-period>
    <v-dialog v-model="subtractDialog" width="500">
      <v-form ref="subtractForm" v-if="subtractTarget">
        <h1>例外{{ pointType }}利用・取り消し</h1>
        <div class="mt-5">
          <h6>利用{{ pointType }}数 (残り{{subtractTarget.pointRemains | toThousands}}ポイント)</h6>
          <tvos-int-input
            style="width: 90%"
            single-line
            v-model="subtractPoint"
            required
          />
        </div>
        <div>
          <h6>{{ pointType }}利用履歴向け備考</h6>
          <v-text-field
            style="width: 90%"
            v-model="subtractReason"
            single-line
            :rules="[$rules.required, $rules.checkLenghInput(255)]"
          ></v-text-field>
        </div>
        <div class="d-flex justify-end">
          <v-btn class="t-btn--red-dark mr-4" @click="subtractDialog = false">
            <v-icon>mdi-close</v-icon>
            {{ $t('task_29786.close') }}
          </v-btn>
          <v-btn class="t-btn--prm" to @click="execSubtract"> 実行 </v-btn>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ChangePeriod from './ChangePeriod.vue';
import SimpleTable from '@/components/Table/simpleTable.vue';

export default {
  components: { ChangePeriod, SimpleTable },
  name: 'ContractDialog',

  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      arrDataTable1: [],
      arrDataTable2: [],
      showLink: -1,
      facilities: [],
      data: '',
      headers2: [
        { text: '発行ID', value: 'id' },
        { text: '発生日(優先度)', value: 'grantedAt' },
        { text: '単価', value: 'pointPrice' },
        {
          text: this.pointType,
          value: 'pointRemains',
          align: 'center',
        },
        { text: '期限開始', value: 'validFrom' },
        { text: '期限終了', value: 'validThru', width: '50%' },
        { text: '備考', value: 'remarks', width: '70%' },
        { text: '期間変更', value: 'check', width: '100px' },
        { text: '例外利用', value: 'subtract', width: '100px' },
      ],
      facilities1: [],
      headers1: [
        { text: '発行ID', value: 'id' },
        { text: '発生日(優先度)', value: 'grantedAt' },
        { text: '単価', value: 'pointPrice' },
        {
          text: this.pointType,
          value: 'pointRemains',
          align: 'center',
        },
        { text: '期限開始', value: 'validFrom' },
        { text: '期限終了', value: 'validThru' },
        { text: '繰越', value: 'goAhead' },
        { text: '前倒', value: 'fallingForward', width: '10%' },
        { text: '備考', value: 'remarks', width: '70%' },
        { text: '期間変更', value: 'check', width: '100px' },
      ],
      subtractTarget: null,
      subtractReason: null,
      subtractPoint: null
    };
  },
  props: {
    visible: Boolean,
    pointType: String,
  },

  watch: {
    getContractPointList() {
      this.getDateTable();
    },
  },

  mounted() {
    this.initFacilities();
    this.contractPointList();
  },
  computed: {
    ...mapGetters(['getContractPointList']),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    subtractDialog: {
      get() { return !!this.subtractTarget },
      set(value) { 
        if (!value) {
          this.subtractTarget = null
        }
      }
    },
    contractId() {
      return parseInt(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions(['contractPointList']),
    ...mapMutations([
      'setContractPointList',
      'setValid',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),
    show(item) {
      const data = {
        id: item.id,
        validFrom: item.validFrom,
        validThru: item.validThru,
      };
      this.setValid(data);
      this.showLink = 1;
    },
    subtract(item) {
      this.subtractTarget = item
    },
    async execSubtract() {
      if (this.$refs.subtractForm.validate()) {
        await this.$store.dispatch(
          'subtractContractPointExc', {
          contractId: this.contractId,
          contractPointId: this.subtractTarget.id,
          exceptionalReason: this.subtractReason,
          points: this.subtractPoint
        })
        await this.contractPointList();
        this.subtractDialog = false
      }
    },
    getDateTable() {
      this.arrDataTable1 = this.getContractPointList.filter(
        item => item.validThru > this.date,
      );
      this.arrDataTable2 = this.getContractPointList.filter(
        item => item.validThru < this.date,
      );
    },

    initFacilities() {
      for (let i = 0; i < 5; i++) {
        this.facilities.push({
          id: '0000' + i + 1,
          datePreferred: '2024/08/01 00:00',
          price: '¥980',
          namePoint: '6',
          periodBegins: '2024/08/01',
          expirationDate: '2024/08/01',
          remarks: 'テストテストテスト',
        });
      }
      for (let i = 0; i < 3; i++) {
        this.facilities1.push({
          id: '0000' + i + 1,
          datePreferred: '2024/08/01 00:00',
          price: '¥980',
          namePoint: '6',
          periodBegins: '2024/08/01',
          expirationDate: '2024/08/01',
          goAhead: '',
          fallingForward: '',
          remarks: 'テストテストテスト',
        });
      }
    },
    save() {
      this.$emit('close');
    },
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
h1 {
  color: #212121;
}
h3 {
  color: #80ced7 !important;
  font-size: 20px;
  font-weight: bold;
}
.v-data-table::v-deep {
  &.--max {
    height: 300px !important;
    overflow-y: auto;
  }
  tbody > tr > td,
  th,
  .v-select__selection {
    font-size: 16px !important;
    color: #424242 !important;
    font-weight: 400 !important;
  }
}

// .t-btn--prm {
//   background-color: #3083dc !important;
// }
.t-btn--red-dark {
  width: 74px !important;
  height: 32px !important;
  .v-btn__content {
    font-size: 14px !important;
  }
}
.mdi-close {
  font-size: 16px !important;
}
::v-deep {
  .v-dialog {
    width: 1340px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 9px;
    .v-label {
      font-size: 10px;
      font-weight: 600;
      color: #000 !important;
    }
  }
  // .v-data-table__wrapper{
  //   max-height: 500px !important;
  // }
  .v-data-table__wrapper,
  .v-card {
    background: #eeeeee !important;
  }

  .fa-calendar-check {
    height: 19px !important;
    width: 17px !important;
  }
  .v-data-table__wrapper {
    max-height: 260px;
    overflow-y: scroll;
  }
}
.table1 {
  .v-data-table__wrapper {
    table {
      thead {
        tr {
          th {
            background: #eee !important;
          }
        }
        tr {
          th:nth-child(1) {
            width: 90px !important;
          }
        }
        tr {
          th:nth-child(2) {
            width: 150px !important;
          }
        }
        tr {
          th:nth-child(3) {
            max-width: 50px !important;
          }
        }
        tr {
          th:nth-child(4) {
            width: 60px !important;
          }
        }
        tr {
          th:nth-child(5) {
            width: 150px !important;
          }
        }
        tr {
          th:nth-child(6) {
            width: 330px !important;
          }
        }
        tr {
          th:nth-child(7) {
            width: 340px !important;
          }
        }
        tr {
          th:nth-child(8), th:nth-child(9) {
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          td:nth-child(8), td:nth-child(9) {
            text-align: center !important;
          }
        }
      }
    }
  }
}
.table2 {
  .v-data-table__wrapper {
    table {
      thead {
        tr {
          th {
            background: #eee !important;
          }
        }
        tr {
          th:nth-child(1) {
            width: 90px !important;
          }
        }
        tr {
          th:nth-child(2) {
            width: 150px !important;
          }
        }
        tr {
          th:nth-child(3) {
            max-width: 50px !important;
          }
        }
        tr {
          th:nth-child(4) {
            width: 60px !important;
          }
        }
        tr {
          th:nth-child(5) {
            width: 130px !important;
          }
        }
        tr {
          th:nth-child(6) {
            width: 100px !important;
          }
        }
        tr {
          th:nth-child(7) {
            width: 90px !important;
          }
        }
        tr {
          th:nth-child(8) {
            width: 90px !important;
          }
        }
        tr {
          th:nth-child(9) {
            width: 300px !important;
          }
        }
        tr {
          th:nth-child(10), th:nth-child(11) {
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          td:nth-child(10), td:nth-child(11) {
            text-align: center !important;
          }
        }
      }
    }
  }
}
</style>
