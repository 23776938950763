<template>
  <div>
    <v-dialog v-model="visible" :width="width" :content-class="className" @click:outside="closeDialog">
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col :cols="12">
            <p class="page-title-main">
            キャンセルしてポイント変換
            </p>
        </v-col>
      </v-row>
      <v-row>
          <v-col :cols="9">
              <p class="page-title-two">
                  キャンセルする日程を選択
              </p>
          </v-col>
          <v-col :cols="3">
            <v-btn
                class="btn-cus"
                @click="CancelAllDay()"
                >
                <p class="v-btn-content-cus">全日程キャンセル</p>
                
            </v-btn>
          </v-col>
      </v-row>
      <div
        v-for="(item, idx) in listenList"
        :key="idx+'pw'"
        class="mt-5"
        shaped
        tile
        >
        <v-row :cols="12">
            <v-card class="mb-5" style="width: 99%;">
                <v-row>
                    <v-col cols="1">
                    </v-col>
                    <v-col cols="4">
                        <label>キャンセル日</label>
                    </v-col>
                    <v-col cols="3">
                        <label>設定キャンセルポリシー</label>
                    </v-col>
                    <v-col cols="2">
                        <label>キャンセル率</label>
                    </v-col>
                    <v-col cols="2">
                        <label>{{ pointName }}</label>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1">
                        <v-checkbox
                        v-model="item.status"
                        style="padding: 0;
                        margin: 0;"
                        class="ml-3"     
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                        <label style=" font-size: 14px !important;">{{item.stayDate}}</label>
                    </v-col>
                    <v-col cols="3">
                        <label style=" font-size: 14px !important;">{{item.penaltyRate}}</label>
                    </v-col>
                    <v-col cols="2" style="padding: 0;">
                        <v-combobox
                        dense
                        :value="item.listPenaltyRatedef"
                        :items="listPenaltyRate"
                        style="width: 60%;margin-left: 20px;color: #424242;font-size: 14px !important;"
                        @change="changeValuePenaltyRate($event,item.idOjb)"
                        ></v-combobox>
                    </v-col>
                    <v-col cols="2">
                        <label style=" font-size: 14px !important;">{{item.refundSpPoint}}</label>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
      </div>
      <v-row>
          <v-col :cols="8">
          </v-col>
          <v-col :cols="3">
              <v-row>
                  <p class="p-cus">合計返却ポイント</p>
              </v-row>
              <v-row>
                  <p class="p-total-point">{{totalRefundPoint}}</p>
              </v-row>
          </v-col>
      </v-row>
      <v-row style="padding: 0;
              margin: 0;">
            <div class="footer" style="width: 100%;">
            <v-row>
                <v-col :cols="6">
                  </v-col>
                  <v-col :cols="6" style="padding: 0;">
                    <div style="float: right;">
                        <template>
                            <v-btn small class="t-btn--red-dark" @click="closeClick">
                              <v-icon>mdi-close</v-icon>
                              <span style="font-size: 14px !important;">&emsp;閉じる</span>
                            </v-btn>
                        </template>

                        <template>
                            <v-btn small class="btn-crm-primary-cus ml-3" @click="actionCancel()">
                            キャンセル実行
                            </v-btn>
                        </template>
                    </div>
                  </v-col>
            </v-row>
        <!-- <slot name="footer"> -->
                    
        <!-- </slot> -->
      </div>
      </v-row>
    </v-form>
    </v-dialog>
  </div>
</template>

<script>
import {  mapActions,mapMutations ,mapGetters} from 'vuex';
import { formatDate } from '@/constants/functions';
export default {
  name: 'ContractDialog',
  props: {
    visible: Boolean,
    title: String,
    className: {
      type: String,
      default: ''
    },
    width: {
      type: [String, Number],
      default: 700,
    },
    id: Number,
    totalSpPoint:Number,
    Checkopen:Boolean,
  },
  data() {
    return {
      checkbox:false,
      listenList:[],
      listPenaltyRate:[],
      valid: false,
      ListdataChecked:[],
      reload: false,
    };
  },
  mounted(){
      for (let i = 0; i <= 100; i++) {
          this.listPenaltyRate.push(i + '%');
      }
  },
  watch:{
    CancelAndConvertPointsID(){
        this.reload = true
    },
    OpenDialog(){
        if (this.reload === true){
          this.loadDataDef();
        }
    }
  },
  computed: {
    ...mapGetters([]),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close',value);
        }
      },
    },
    CancelAndConvertPointsID:{
      get() {
        return this.id;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    OpenDialog:{
      get() {
        return this.Checkopen;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    bookingsToCancel() {
      return this.listenList.filter(x=>x.status === true)
    },
    totalRefundPoint () {
      return this.listenList.reduce((sum, item) => sum + item.refundSpPoint, 0)
    },
    typePoint () {
      return this.$route.params.type
    },
    pointName() {
      return this.typePoint === 'PW' ? 'SP' : 'TVP'
    }
  },
  methods: {
    ...mapActions(['mwGetCalculateBookingCancelPenalty','ActionCalculateBookingCancelPenalty']),
    ...mapMutations([
      'setAlertSuccess',
      'setDialogSuccess',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),
    closeDialog(requestReload) {
      if (requestReload === true) {
        this.$emit('requestReload')
      }
      this.$emit('close');
    },
    clearData(){
      this.$refs.form.reset();
      return this.loadDataDef();
    },
    async closeClick () {
      await this.clearData();
      this.closeDialog();
    },
    CancelAllDay(){
        this.listenList.forEach(element => {
            element.status = true;
        });
    },
    changeValuePenaltyRate(val,idx){
        const vl = parseInt(val.split('%')[0]);
        const penaltyRate = vl / 100;
        const row = this.listenList[idx]
        row.penaltySpPoint =  Math.floor(row.originalSpPoint * penaltyRate);
        row.penaltyRates = penaltyRate;
        this.setRefundPoint(idx);
    },
    setRefundPoint (index) {
      const row = this.listenList[index];
      const {originalSpPoint, penaltySpPoint} = row;
      row.refundSpPoint = originalSpPoint - penaltySpPoint;
      this.$set(this.listenList, index, row)
    },
    async loadDataDef(){
      this.listenList = []
      if (this.id) {
        await this.mwGetCalculateBookingCancelPenalty(this.id).then((re) => {
          if (re.data.calculateBookingCancelPenalty.penalties.length > 0){
            const penalties = re.data.calculateBookingCancelPenalty.penalties;
            for (let i = 0; i < penalties.length; i++) {
              const element = penalties[i];
              if (this.typePoint === 'PW') {
                const ojb = {
                  idOjb: i,
                  stayDate: formatDate(element.stayDate),
                  stayDatedef: element.stayDate,
                  penaltyRate: (element.penaltyRate * 100) +'%',
                  penaltyRates: element.penaltyRate,
                  listPenaltyRatedef: element.penaltyRate * 100 +'%',
                  penaltySpPoint: element.penaltySpPoint,
                  originalSpPoint: element.spPoint,
                  status: false
                }
                this.listenList.push(ojb)
              } else if (this.typePoint === 'VM'){
                const ojb = {
                  idOjb: i,
                  stayDate: formatDate(element.stayDate),
                  stayDatedef: element.stayDate,
                  penaltyRate: (element.penaltyRate * 100) +'%',
                  penaltyRates: element.penaltyRate,
                  listPenaltyRatedef: element.penaltyRate * 100 +'%',
                  penaltySpPoint: element.penaltyVmRightPrice,
                  originalSpPoint: element.vmRightPrice,
                  status: false
                }
                this.listenList.push(ojb)
              }
              this.setRefundPoint(this.listenList.length - 1)
            }
          }
        });
      }
    },
    actionCancel(){
        this.$refs.form.validate();
        this.ListdataChecked = [];
        this.ListdataChecked = this.listenList.filter(x=>x.status === true);
        const penaltyRatesArr = [];
        this.ListdataChecked.forEach(element => {
            penaltyRatesArr.push({
                stayDate: element.stayDatedef,
                penaltyRate: element.penaltyRates
            });
        });
        const data = {
            id:this.id,
            penaltyRates:penaltyRatesArr
        }
        if (this.ListdataChecked.length === 0) {
          this.setAlertError('キャンセル日が未選択です');
        } else {
          this.ActionCalculateBookingCancelPenalty(data).then((result) => {
            this.clearData();
            this.closeDialog(true);
          })
        }
        
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  background-color: #fff;
  padding: 20px;
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    button:not(:last-child) {
      margin-right: 10px;
    }
  }
  label{
    text-align: left;
    font: normal normal 600 10px/15px Noto Sans;
    font-size: 10px !important;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
   }
    
    .btn-crm-primary-cus {
      background: #1873D9 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
        .v-btn__content {
            font-size: 14px !important;
            font: normal normal medium 14px/21px Noto Sans;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }
    }
}
.page-title-main {
font: normal normal bold 28px/38px Noto Sans;
font-size: 28px !important;
letter-spacing: 0px;
color: #212121 !important;
opacity: 1;
}
.page-title-two{
font: normal normal bold 20px/24px Noto Sans;
letter-spacing: 0px;
color: #004F8B !important;
opacity: 1;
}
.btn-cus{
float: right;
border: 1px solid var(--error_delete_c92d2d);
border: 1px solid #C92D2D;
border-radius: 4px;
opacity: 1;
}
.card-cus{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000005D;
    border-radius: 3px;
    opacity: 1;
}
.v-btn-content-cus{
    font: normal normal medium 14px/21px Noto Sans;
    letter-spacing: 0px;
    color: #C92D2D;
    opacity: 1; 
    padding: 0;
    margin: 0;  
}
.p-cus{
    font: normal normal 600 10px/15px Noto Sans;
    font-size: 10px !important;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
}
.p-total-point{
    font: normal normal medium 14px/21px Noto Sans;
    font-size: 14px !important;
    letter-spacing: 0px;
    color: #444444 !important;
    opacity: 1;
}
</style>
