<template>
  <v-dialog v-model="dialog">
    <h1 class="px-10">{{ $route.params.type }}期間変更</h1>
    <v-row class="w-95 ml-auto px-5 pt-5">
      <v-col cols="6" sm="6">
        <p for="">期限開始</p>

        <div v-for="item in listDate" :key="item.id">
          <v-menu
            v-model="menuDate[listDate.indexOf(item)]"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <div class="d-flex justify-start">
                <v-text-field
                  readonly
                  dense
                  :value="formatDate(getValid.validFrom)"
                  v-on="on"
                ></v-text-field>
              </div>
            </template>
            <v-date-picker
              v-model="getValid.validFrom"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              @input="menuDate[listDate.indexOf(item)] = false"
              class="v-date-picker-custom"
            ></v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="6" sm="6">
        <p for="">期限終了</p>
        <div v-for="item in listDate1" :key="item.id">
          <v-menu
            v-model="menuDate1[listDate.indexOf(item)]"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <div class="d-flex justify-start">
                <v-text-field
                  readonly
                  dense
                  :value="formatDate(getValid.validThru)"
                  v-on="on"
                ></v-text-field>
              </div>
            </template>
            <v-date-picker
              v-model="getValid.validThru"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              @input="menuDate1[listDate.indexOf(item)] = false"
              class="v-date-picker-custom"
            ></v-date-picker>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-end my-5 px-5">
      <v-btn class="t-btn--red-dark" @click="closeDialog">
        <v-icon>mdi-close</v-icon>
        閉じる
      </v-btn>
      <v-btn small class="t-btn--prm ml-3" @click="save"> 保存 </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'ChangePeriod',
  data() {
    return {
      id: null,
      validFrom: '',
      validThru: '',
      valid: false,
      row: null,

      isShowDate: false,
      dates: [],
      menuDate: [],
      menuDate1: [],
      listDate: [{ id: 1, date: new Date().toISOString().substr(0, 10) }],
      listDate1: [{ id: 1, date: new Date().toISOString().substr(0, 10) }],
    };
  },
  props: {
    visible: Boolean,
    showLink: Boolean,
  },

  computed: {
    ...mapGetters(['getValid']),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapMutations(['setValid']),
    ...mapActions(['updateContractPointDuration']),

    save() {
      const data = {
        id: this.getValid.id,
        validFrom: this.getValid.validFrom,
        validThru: this.getValid.validThru,
      };
      this.setValid(data);
      this.updateContractPointDuration();
      this.$emit('close');
    },
    closeDialog() {
      this.$emit('close');
    },
    formatDate(date) {
      return date.replace(/-/g, '/');
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 28px;
  color: #212121;
  font-weight: bold;
}
p {
  color: #3083dc;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0 !important;
}
.v-btn {
  font-size: 14px !important;
  width: 72px !important;
  height: 33px !important;
  i {
    font-size: 16px;
  }
}
.w-95 {
  width: 95%;
}

::v-deep {
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 400px;
    padding: 17px;
    background-color: #fff !important;
    border-radius: 9px;
  }
  .v-input {
    margin-top: 0;
    &.w-50 {
      width: 50%;
      max-width: 50%;
    }
    &.sort-input {
      width: 180px;
    }
    &.date-text input {
      font-size: 12px;
    }
  }
  label:not(.normal):not(.v-label) {
    font-size: 10px;
  }
  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: #3083dc !important;
    border: 1px solid #3083dc !important;
  }
}
</style>