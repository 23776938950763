<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeDialog">
      <v-form ref="form">
        <h1>{{ $route.params.type }}繰越前倒し</h1>

        <v-row class="mt-3">
          <v-col cols="4">
            <h5 class="float-right">期限終了</h5>
          </v-col>
          <v-col>
            <h5>{{ formatDate(getValidCarry.validTo) }}</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <h5 class="float-right">{{ $route.params.type }} 数</h5>
          </v-col>
          <v-col>
            <h5>{{ getValidCarry.points }}</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4 my-auto">
            <h5 class="float-right">移動 {{ $route.params.type }}</h5>
          </v-col>
          <!-- <template v-if="statusSelect"> -->
          <v-col>
            <v-text-field
              v-model="quantity"
              :rules="rules.point"
            />
          </v-col>
          <!-- </template> -->
        </v-row>
        <v-row style="margin-top: -40px !important">
          <v-col cols="4 my-auto">
            <h5 class="float-right">備考</h5>
          </v-col>
          <v-col>
            <v-text-field single-line v-model="remarks" :rules="[$rules.required, $rules.checkLenghInput(255)]"></v-text-field>
          </v-col>
        </v-row>

        <div class="d-flex justify-end mt-9">
          <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
            {{ $t('task_29786.close') }}
          </v-btn>
          <v-btn class="t-btn--prm mr-4" to @click="save"> 前倒し </v-btn>
          <v-btn class="t-btn--prm" to @click="saveChange"> 繰越し </v-btn>
        </div>
      </v-form>
    </v-dialog>

    <carry-over-service
      :visible="visibleChange === 1"
      :year="year"
      @reset="$refs.form.reset()"
      :quantity="parseInt(quantity)"
      @close="visibleChange = -1"
    ></carry-over-service>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
// import { CheckLenghInput } from '@/utils/validate';
import CarryOverService from './Carry-overService.vue';
import moment from 'moment';
export default {
  components: { CarryOverService },
  name: 'CarryForward',
  data() {
    return {
      carryData: {},
      quantity: null,
      visibleChange: -1,
      lableSe: this.$route.params.type + '数',
      remarks: '',
      validTo: null,
      rules: {
        point: [(v) => !!v || this.$t('rules.isRequired'),
        v => this.checkNumber(v) || this.$t('rules.isNumber'),
        v => parseInt(v) >= 1 || '1以上の値を入力下さい'],

        // remarks: [
        //   (v) => CheckLenghInput(v, 255) || this.$t('rules.maximumNCharacter', { value: 255 }),
        // ],
      },
    };
  },

  props: {
    statusSelect: Boolean,
    visible: Boolean,
    year: Number,
    pointRemains: Number,
    contractId: Number,
  },

  computed: {
    ...mapGetters(['getValidCarry']),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    pointNumbers() {
      var arr = [];
      for (let i = 1; i <= 100; i++) {
        arr.push(i);
      }
      return arr;
    },
  },
  mounted() {},
  methods: {
    ...mapActions(['advancePointsCarry']),
    ...mapMutations([
      'setValidCarry',
      // Alert
      'setAlertSuccess',
      'closeDialog',
    ]),

    checkNumber (v) {
      return Number.isInteger(Number(v))
    },

    saveChange() {
      if (this.$refs.form.validate()) {
        this.visibleChange = 1;
        this.points = null;

        this.$emit('close');
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        const carryData = {
          contractId: parseInt(this.$router.currentRoute.params.id),
          year: parseInt(this.year),
          points: parseInt(this.quantity),
          remarks: this.remarks,
          validTo: this.getValidCarry.validTo,
        };
        this.advancePointsCarry(carryData);
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$refs.form.reset();
      this.$emit('close');
    },

    formatDate(date) {
      return moment(date).format('YYYY/MM/DD');
    },
  },
};
</script>

<style lang="scss" scoped>
.textUnitPrice {
  font-size: 15px;
  color: gray;
}
h1 {
  color: #212121;
  font-size: 28px;
  font-weight: bold;
}
h5 {
  font-size: 18px;
  color: #666666;
  font-weight: 400;
}
.row + .row {
  margin-top: 0 !important;
}
.v-btn {
  height: 32px !important;
}
.t-btn--red-dark {
  width: 80px !important;
}
.t-btn--prm {
  width: 70px !important;
}
::v-deep {
  .selectMembership .v-select__selection {
    color: red;
  }
  .v-dialog {
    width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .v-input {
    max-width: 90% !important;
  }
  .v-select {
    &__slot {
      .v-label {
        color: #666666 !important;
        &--active {
          font-size: 12px;
          color: #3083dc;
        }
      }
      .primary--text {
        color: #3083dc !important;
      }
    }
    .v-input {
      &__slot {
        color: #3083dc !important;
        caret-color: #3083dc !important;
      }
    }
  }
  .theme--light.v-label {
    color: #000000;
    .primary--text {
      color: #3083dc !important;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-icon__svg {
    color: #333 !important;
  }
  .v-select__selection--comma {
    font-size: 18px !important;
    color: #666666 !important;
  }
}
</style>
