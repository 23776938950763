<template>
  <div>
    <contract-detail
      v-if="$route.params.type === 'FP' || $route.params.type === 'SP'"
    >
      <!-- Title -->
      <template v-slot:title>{{ $route.params.type }}契約</template>

      <!-- End Title -->

      <template v-slot:cancel>
        <v-btn small class="t-btn--red-dark" @click="$windowClose">
          <v-icon>mdi-close</v-icon>
          <span>&emsp;閉じる</span>
        </v-btn>
      </template>

      <template v-slot:send>
        <v-btn
          small
          class="t-btn--prm ml-3"
          @click="actionUpdate()"
          :disabled="!checkPerUser"
        >
          保存
        </v-btn>
      </template>
      <!-- End Button after title  -->

      <!-- Content  -->
      <template v-slot:default="{ isEffective }">
        <template>
          <v-card
            class="pa-5 v-card-responsive-table size-component"
            color="#FFFFFF"
          >
            <div class="mb-2">
              <div class="d-flex justify-space-between">
                <span class="text-title-component font-weight-bold"
                  >{{ $route.params.type }}状況( {{ formatDate(date) }}現在 )
                </span>
                <div>
                  <v-btn
                    class="t-btn--prm mr-2"
                    small
                    :disabled="!checkPerUser || !isEffective"
                    @click="redirectPMS"
                  >
                    予約作成
                  </v-btn>
                  <v-btn
                    class="t-btn--prm mr-2"
                    @click="dialog = 2"
                    small
                    :disabled="!checkPerUser || !isEffective"
                  >
                    {{ $route.params.type }}提携施設予約作成
                  </v-btn>
                  <v-btn
                    class="t-btn--prm mr-2"
                    @click="dialog = 3"
                    small
                    :disabled="!checkPerUser || !isEffective"
                  >
                    例外{{ $route.params.type }}利用・取り消し
                  </v-btn>
                  <v-btn
                    class="t-btn--prm mr-2"
                    @click="dialog = 6"
                    small
                    :disabled="!checkPerUser || !isEffective"
                  >
                    ギフトチケット購入
                  </v-btn>
                </div>
              </div>
              <span class="text-title-span"
                >期限内{{ $route.params.type }}数:
                {{ getHidePoint }}
              </span>
            </div>
            <SimpleTable className="table1">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-for="(item, i) in headers" :key="i">
                      {{ item.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="dataTable1.length > 0">
                    <tr v-for="(item, index) in dataTable1" :key="index">
                      <td>
                        {{ item.pointRemains }}
                      </td>
                      <td>
                        {{ formatDateChar(item.validFrom) }}
                      </td>
                      <td>
                        {{ formatDateChar(item.validTo) }}
                      </td>
                      <td>
                        <v-btn
                          small
                          class="t-btn--prm"
                          @click="
                            getYear(item.year);
                            dialog = 1;
                            openAddPurchase += 1;
                          "
                          :disabled="!checkPerUser || !isEffective"
                        >
                          {{ $route.params.type }}追加購入
                        </v-btn>
                      </td>
                      <td>
                        <v-btn
                          class="t-btn--prm"
                          icon
                          @click="
                            forward(item);
                            getYear(item.year);
                            getPoint(item.pointRemains);
                          "
                          :disabled="!checkPerUser || !isEffective"
                        >
                          <i
                            class="fas fa-people-carry"
                            style="color: #ffffff"
                          />
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td class="text-center" colspan="5">
                      {{ $t('rules.noData') }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </SimpleTable>
            <div class="my-6">
              <span class="text-title-component font-weight-bold"
                >期限切れ {{ $route.params.type }}
              </span>
            </div>
            <SimpleTable className="table2">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-for="(item, i) in headersTable" :key="i">
                      {{ item.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="dataTable2.length > 0">
                    <tr v-for="(item, index) in dataTable2" :key="index">
                      <td>
                        {{ item.pointRemains }}
                      </td>
                      <td>
                        {{ formatDateChar(item.validFrom) }}
                      </td>
                      <td>
                        {{ formatDateChar(item.validTo) }}
                      </td>

                      <td>
                        <v-btn
                          class="t-btn--prm"
                          icon
                          @click="
                            forward(item);
                            getYear(item.year);
                            getPoint(item.pointRemains);
                          "
                          :disabled="!checkPerUser"
                        >
                          <i
                            class="fas fa-people-carry"
                            style="color: #ffffff"
                          />
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td class="text-center" colspan="4">
                      {{ $t('rules.noData') }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </SimpleTable>
          </v-card>
          <!-- End Within the deadline  -->

          <!-- History -->
          <div style="width: 80%" class="mb-16">
            <div class="pr-5 d-flex flex-row-reverse">
              <v-btn
                color="#FFFFFF"
                small
                class="d-flex align-center btn-add"
                @click="dialog = 4"
                :disabled="!checkPerUser"
              >
                ポイント内訳を表示
              </v-btn>
            </div>
            <v-card
              class="pa-5 ml-6 mt-3 v-card-responsive-table size-component"
              style="margin: 0px"
              color="#FFFFFF"
            >
              <v-row>
                <v-col :cols="6">
                  <div class="mb-2">
                    <span
                      class="text-title-component font-weight-bold"
                      style="color: #004f8b"
                      >{{ $route.params.type }} 履歴</span
                    >
                  </div>
                </v-col>
              </v-row>
              <Table
                :attr="{
                  dense: true,
                  'no-data-text': $t('rules.noData'),
                  'item-key': 'id',
                }"
                ref="table"
                :itemsPerPage="10"
                :itemsPerPageOptions="[10, 20, 50, 100, 200, 500]"
                :headers="pointUsageHistoryHeader"
                :items="groupedContractPointMovementList"
                multi-sort
              >
                <template v-slot:[`item.pointChange`]="{ item }">
                  <span
                    :style="
                      item.pointChange > 0 ? 'color: #3083DC' : 'color: #DB3069'
                    "
                  >
                    {{ item.pointChange }}
                  </span>
                </template>

                <template v-slot:[`item.changedAt`]="{ item }">
                  {{ formatDateChar(item.changedAt) }}
                </template>
              </Table>

              <breakdown
                :visible="dialog === 4"
                :price-type="$route.params.type"
                @close="dialog = -1"
              />

              <PointExchangeOrderDialog
                :value="dialog === 6"
                :point="getHidePoint"
                :clientId="clientId"
                :contractId="contractID"
                :priceType="isSp ? 'spPoint' : 'fpPoint'"
                @input="dialog = -1"
              />
              <!-- From dialog 1 -->
              <ContractDialog
                :visible="dialog === 5"
                @close="dialog = -1"
                title="MW編集"
                className="custom-dialog"
              >
                <template v-slot:content>
                  <div class="d-flex">
                    <label class="mr-3">繰越料金単価</label>300円
                  </div>
                  <div class="d-flex">
                    <label class="mr-3">繰越ポイント数</label>50
                  </div>
                  <div class="d-flex align-center">
                    <label class="mr-3">請求金額</label>
                    <v-select label="繰越手数料" :items="[1, 2, 3]" />
                  </div>
                </template>
                <template v-slot:footer>
                  <v-btn class="t-btn--pink" @click="dialog = 1">{{
                    $t('buttons.cancel')
                  }}</v-btn>
                  <v-btn class="t-btn--blue" :disabled="!checkPerUser"
                    >請求作成して完了</v-btn
                  >
                </template>
              </ContractDialog>
              <!--  -->
            </v-card>
          </div>
          <!-- End History -->

          <!-- Dialog -->
          <additional-purchase
            :visible="dialog === 1"
            :key="openAddPurchase"
            :year="year"
            :contractId="contractID"
            @close="dialog = -1"
          ></additional-purchase>
          <point-affiliated-facility-reservation
            :visible="dialog === 2"
            :id="contractID"
            :clientId="clientId"
            :type="$route.params.type"
            @close="dialog = -1"
          ></point-affiliated-facility-reservation>
          <exception :visible="dialog === 3" @close="dialog = -1" @completed="reload"></exception>
          <carry-forward
            :visible="dialog === 7"
            :contractId="contractID"
            :pointRemains="pointRemains"
            :year="year"
            @close="dialog = -1"
          ></carry-forward>
        </template>
      </template>
      <!-- End Content  -->
    </contract-detail>
    <contract-detail v-else>
      <!-- Title -->
      <template v-slot:title>{{ $route.params.type }}契約</template>
      <!-- End Title -->

      <template v-slot:cancel>
        <v-btn small class="t-btn--red-dark">
          <v-icon>mdi-close</v-icon>
          <span>&emsp;閉じる</span>
        </v-btn>
      </template>

      <template v-slot:send>
        <v-btn
          small
          class="t-btn--prm ml-3"
          :disabled="!checkPerUser"
          @click="actionUpdate()"
        >
          保存
        </v-btn>
      </template>
      <!-- End Button after title  -->
      <!-- Content 1  -->
      <div class="mt-4 pa-5" style="margin-left: 25px;">
        <h2
          class="text--title my-4"
          style="text-align: left;
        font: normal normal bold 20px/24px Noto Sans;
        letter-spacing: 0px;
        color: #004F8B;
        opacity: 1;"
        >
          利用状況
        </h2>
        <h2
          class="text--title my-4"
          style="text-align: left;
        font: normal normal medium 18px/24px Noto Sans;
        letter-spacing: 0px;
        color: #004F8B;
        opacity: 1;"
        >
          PW契約
        </h2>
      </div>
      <v-card
        v-for="(item, idx) in ArrYear"
        :key="idx + 'pw'"
        class="pa-5 mx-table mt-4"
        shaped
        tile
      >
        <div class="d-flex justify-space-between align-center mb-3">
          <div class="d-flex align-center"></div>
        </div>
        <v-data-table
          :headers="headersPW"
          :items="filterYear(item, facilitiesPWContract, 0)"
          :hide-default-footer="true"
          :no-data-text="$t('rules.noData')"
          class="elevation-1 custom-table custom-table1 pw-summary-table"
        >
        </v-data-table>
        <div class="span-custom-pw"></div>
        <v-data-table
          :headers="HeadersExpiredPWContract"
          :items="filterYearExpired(item, facilitiesExpiredPWContract)"
          class="elevation-1 custom-table mt-n4 "
          :hide-default-footer="true"
          style="box-shadow: none !important;"
          :no-data-text="$t('rules.noData')"
        >
          <template v-slot:[`item.UsageConfirmation`]="{ item }">
            <div v-if="item.UsageConfirmation == null">
              <v-btn
                v-text="UseUnconfirmed"
                v-model="item.UsageConfirmation"
                class="pw-custom"
                style="background: #F5511E 0% 0% no-repeat padding-box;
              border-radius: 6px;
              opacity: 1;
              text-align: center;"
                :disabled="!checkPerUser"
                @click="ChangeUseUnconfirmed(item.idExpired, 0)"
              >
                <span>&emsp;閉じる</span>
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                v-text="labelUsageConfirmation"
                class="pw-custom"
                style="background: #FFC3B1 0% 0% no-repeat padding-box;
              border-radius: 6px;
              opacity: 1;
              text-align: center;"
                :disabled="!checkPerUser"
                @click="ChangeUseUnconfirmed(item.idExpired, 1)"
              >
                <span>&emsp;閉じる</span>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.ReservationConfirmationPMS`]="{ item }">
            <div style="float: right;">
              <v-btn
                class=".PMS-btn"
                @click="redirectPMS_PW(item.reservationID)"
                style="background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 0.5px solid #1873D9;
                border-radius: 4px;
                opacity: 1;"
              >
                <p
                  style="display: contents;
                    text-align: left;
                    font: normal normal 600 12px/15px Noto Sans;
                    letter-spacing: 0px;
                    color: #1873D9;opacity: 1;"
                >
                  PMSで予約確認
                </p>
              </v-btn>
              <v-btn
                :disabled="!checkPerUser"
                class=".PMS-btn-Cancel"
                style="margin-left: 2%;;background:#DB3069;"
                v-if="item.show"
              >
                <p
                  style="display: contents;
                        text-align: center;
                        font: normal normal medium 12px/21px Noto Sans;
                        letter-spacing: 0px;
                        color: #FFFFFF;opacity: 1;"
                  @click="CancelAndConvertPoints(item)"
                >
                  キャンセルしてポイント変換
                </p>
              </v-btn>
              <v-btn
                :disabled="!checkPerUser"
                class=".PMS-btn-Cancel"
                style="margin-left: 2%;background:#e0e0e0;"
                v-else
              >
                <p
                  style="display: contents;
                        text-align: center;
                        font: normal normal medium 12px/21px Noto Sans;
                        letter-spacing: 0px;
                        color: #FFFFFF;opacity: 1;"
                >
                  キャンセルしてポイント変換
                </p>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
      <!-- End Content  -->
      <!-- Content 2  -->
      <div class="mt-4 pa-5" style="margin-left: 25px;">
        <h2
          class="text--title my-4"
          style="text-align: left;
        font: normal normal medium 18px/24px Noto Sans;
        letter-spacing: 0px;
        color: #004F8B;
        opacity: 1;"
        >
          期限切れのPW契約
        </h2>
      </div>
      <v-card
        v-for="(item, idx) in ArrYearCheckOut"
        :key="idx"
        class="pa-5 mx-table mt-4"
        shaped
        tile
      >
        <div class="d-flex justify-space-between align-center mb-3">
          <div class="d-flex align-center"></div>
        </div>
        <v-data-table
          :headers="headersPW"
          :items="filterYear(item, facilitiesPWContract, 1)"
          :hide-default-footer="true"
          class="elevation-1 custom-table custom-table1 pw-summary-table"
          :no-data-text="$t('rules.noData')"
        >
        </v-data-table>
        <div class="span-custom-pw"></div>
        <v-data-table
          :no-data-text="$t('rules.noData')"
          :headers="HeadersExpiredPWContract"
          :items="filterYearExpired(item, facilitiesExpiredPWContract)"
          class="elevation-1 custom-table mt-n4 "
          :hide-default-footer="true"
          style="box-shadow: none !important;"
        >
          <template v-slot:[`item.UsageConfirmation`]="{ item }">
            <div v-if="item.UsageConfirmation == null">
              <v-btn
                v-text="UseUnconfirmed"
                v-model="item.UsageConfirmation"
                class="pw-custom"
                style="background: #F5511E 0% 0% no-repeat padding-box;
              border-radius: 6px;
              opacity: 1;
              text-align: center;"
                @click="ChangeUseUnconfirmed(item.idExpired, 0)"
              >
                <span>&emsp;閉じる</span>
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                v-text="labelUsageConfirmation"
                class="pw-custom"
                style="background: #FFC3B1 0% 0% no-repeat padding-box;
              border-radius: 6px;
              opacity: 1;
              text-align: center;"
                @click="ChangeUseUnconfirmed(item.idExpired, 1)"
              >
                <span>&emsp;閉じる</span>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.ReservationConfirmationPMS`]="{ item }">
            <v-btn
              class=".PMS-btn"
              @click="redirectPMS_PW(item.reservationID)"
              style="float: right;background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 0.5px solid #1873D9;
            border-radius: 4px;
            opacity: 1;"
            >
              <p
                style="display: contents;
                text-align: left;
                font: normal normal 600 12px/15px Noto Sans;
                letter-spacing: 0px;
                color: #1873D9;opacity: 1;"
              >
                PMSで予約確認
              </p>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <div style="height: 200px"></div>
      <!-- From dialog 1 -->
      <ContractDialog
        :visible="dialog === 8"
        @close="dialog = -1"
        className="custom-dialog"
        :width="180"
      >
        <template v-slot:content v-if="statusSelect">
          <div class="d-flex align-center">
            <v-select
              v-model="selectedYes"
              :items="listSelected"
              item-text="text"
              item-value="id"
              style="height: 40px;"
              @change="changeSelected(selectedYes)"
            />
          </div>
        </template>
      </ContractDialog>
      <CalculateBookingCancelPenalty
        :visible="dialog === 9"
        :id="this.CancelAndConvertPointsID"
        :Checkopen="this.open"
        :totalSpPoint="this.Spoint"
        @close="dialog = -1"
        @requestReload="$reloadRoute"
      >
      </CalculateBookingCancelPenalty>
    </contract-detail>
  </div>
</template>

<script>
import ContractDialog from '@/components/dialog/ContractDialog';
import CalculateBookingCancelPenalty from './CalculateBookingCancelPenalty.vue';
import PointAffiliatedFacilityReservation from './PointAffiliatedFacilityReservation.vue';
import AdditionalPurchase from './AdditionalPurchase.vue';
import Exception from './Exception.vue';
import contractDetail from '../../ContractDetail.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Breakdown from './Breakdown.vue';
import moment from 'moment';
import router from '@/router';
import CarryForward from './CarryForward.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import SimpleTable from '@/components/Table/simpleTable.vue';
import Table from '@/components/Table/index.vue';
import PointExchangeOrderDialog from '@/components/dialog/PointExchangeOrderDialog.vue';

export default {
  name: 'SPUsageStatus',
  data() {
    return {
      openAddPurchase: 0,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      dataTable1: [],
      dataTable2: [],
      pointRemains: null,
      date: this.$today(),
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      visible: -1,
      dialog: -1,
      facilities: [],
      year: -1,
      contractID: null,
      headers: [
        { text: this.$route.params.type, value: 'pointRemains' },
        { text: '期限開始', value: 'validFrom' },
        { text: '期限終了', value: 'validTo', width: '70%' },
        { text: '追加購入', value: 'AdditionalPurchase', width: '100px' },
        {
          text: '繰越・前倒し',
          value: 'advance',
          width: '50px',
          align: 'center',
        },
      ],
      headersTable: [
        { text: this.$route.params.type, value: 'pointRemains' },
        { text: '期限開始', value: 'validFrom' },
        { text: '期限終了', value: 'validTo', width: '70%' },
        {
          text: '繰越・前倒し',
          value: 'advance',
          width: '50px',
          align: 'center',
        },
      ],
      pointUsageHistoryList: [],
      pointUsageHistoryHeader: [
        { text: '処理日時', value: 'changedAt' },
        { text: '処理内容', value: 'reason' },
        { text: 'ポイント有効期間', value: 'validity' },
        { text: '宿泊施設', value: 'facility' },
        { text: '宿泊日', value: 'stayDate' },
        { text: '増減', value: 'pointChange' },
        { text: '備考', value: 'remarks' },
      ],
      // PW
      show: false,
      isUdo: true,
      statusSelect: true,
      labelUsageConfirmation: '利用確認済み',
      UseUnconfirmed: '利用未確認',
      facilitiesPWContract: [],
      facilitiesExpiredPWContract: [],
      contractApi: {},
      pwContractUsageApi: [],
      bookingGroup: [],
      validity: [],
      ArrYear: [],
      ArrYearCheckOut: [],
      selectedYes: 1,
      headersPW: [
        { text: '年度', value: 'year', width: '3%', sortable: false },
        {
          text: '権利施設',
          value: 'rightsFacility',
          width: '5%',
          sortable: false,
        },
        { text: '週', value: 'week', width: '3%', sortable: false },
        {
          text: '発生日(優先度)',
          value: 'dateOfOccurrence',
          width: '8%',
          sortable: false,
        },
        {
          text: '期限開始',
          value: 'deadlineStart',
          width: '8%',
          sortable: false,
        },
        {
          text: '期限終了',
          value: 'expirationDate',
          width: '8%',
          sortable: false,
        },
        {
          text: 'ポイント単価',
          value: 'pointUnitUrice',
          width: '4%',
          sortable: false,
        },
      ],
      HeadersExpiredPWContract: [
        { text: 'ステータス', value: 'status', width: '8%', sortable: false },
        {
          text: '予約ID',
          value: 'reservationID',
          width: '8%',
          sortable: false,
        },
        { text: '施設', value: 'institution', width: '6%', sortable: false },
        {
          text: '宿泊開始日',
          value: 'accommodationStartDate',
          width: '10%',
          sortable: false,
        },
        { text: '最終日', value: 'lastDay', width: '10%', sortable: false },
        { text: '部屋タイプ', value: 'roomType', width: '8%', sortable: false },
        {
          text: '利用確認',
          value: 'UsageConfirmation',
          align: 'center',
          width: '8%',
          sortable: false,
        },
        { text: 'SP合計', value: 'SPTotal', width: '8%', sortable: false },
        { text: '', value: 'ReservationConfirmationPMS', sortable: false },
      ],
      bookingId: null,
      listSelected: [
        { text: '利用未確認', id: 1 },
        { text: '利用確認済み', id: 2 },
      ],
      // table 1
      idPW: null,
      yearPW: null,
      rightsFacility: '',
      week: null,
      dateOfOccurrence: null,
      deadlineStart: null,
      expirationDate: null,
      pointUnitUrice: null,
      // end

      // table 2
      idExpired: null,
      status: '',
      reservationID: null,
      institution: '',
      accommodationStartDate: '',
      lastDay: '',
      roomType: '',
      UsageConfirmation: '',
      SPTotal: null,
      ReservationConfirmationPMS: null,
      yearExpired: null,
      CancelAndConvertPointsID: null,
      Spoint: null,
      listCancel: [],
      open: false,
      facilitiName: '',
      DateTimeNow: null,
      yearNow: parseInt(new Date().getFullYear()),
      ArrYearfillter: [],
      TypeContract: this.$route.params.type,
      arrayListMaxDateByYear: [],
    };
  },
  mounted() {
    this.contractPointSummaryList();
    this.contractPointMovementList();
    this.contractID = parseInt(router.currentRoute.params.id);
    this.basicInformationList(router.currentRoute.params.id);
    // PW
    if (this.TypeContract === 'PW') {
      this.DateTimeNow = this.DateNowCus();
      this.pwContractUsageList().then(() => {
        this.initFacilities();
      });
    }
  },

  watch: {
    getStatusAddNewPurchasePoints() {
      this.reload();
    },
    getContractPointSummary() {
      this.getDateTable();
    },
    getDialogSuccess() {
      this.pwContractUsageList().then(() => {
        this.initFacilities();
      });
      this.contractID = parseInt(router.currentRoute.params.id);
    },
  },
  computed: {
    ...mapGetters([
      'getContractPointSummary',
      'getContractPointMovementList',
      'getStatusAddNewPurchasePoints',
      'getBasicInfors',
      'getpwContractUsageList',
      'getDialogSuccess',
    ]),
    contractPointMovementListRows () {
      return this.getContractPointMovementList.map(row => {
        return {
          changedAt: row.changedAt,
          reason: row.reason === '繰越' ? '繰越し' : row.reason, // see https://kujira.backlog.com/view/TO2020-848#comment-271884250
          validity: `${this.formatDateChar(row.validFrom)} - ${this.formatDateChar(row.validThru)}`,
          facility: row.booking?.room.roomType.facility.name ?? '-',
          stayDate: row.stayDate ? this.formatDateChar(row.stayDate) : '-',
          pointChange: row.pointChange,
          remarks: row.remarks,
          bookingId: row.bookingId,
          pointExchangeId: row.pointExchangeId
        };
      });
    },
    groupedContractPointMovementList () {
      // TO2020-848: group the rows if the diff of changedAt is 5 seconds or less
      const rows = this.contractPointMovementListRows
      if (rows.length === 0) return []

      const grouped = [];
      rows.forEach(row => {
        const lastGroup = grouped[grouped.length - 1];
        if (lastGroup) {
          if (
            lastGroup.reason === row.reason &&
            lastGroup.validity === row.validity &&
            lastGroup.bookingId === row.bookingId &&
            lastGroup.stayDate === row.stayDate &&
            lastGroup.pointExchangeId === row.pointExchangeId &&
            lastGroup.remarks === row.remarks
          ) {
            // compare the changedAt to see if it's within 5 seconds
            const prev = new Date(lastGroup.changedAt)
            const curr = new Date(row.changedAt)
            if (Math.abs(prev - curr) <= 5000) {
              lastGroup.pointChange += row.pointChange
              lastGroup.changedAt = row.changedAt
              return
            }
          }
        }
        
        grouped.push(row);
      });

      return grouped;
    },
    getHidePoint() {
      var total = 0;
      this.dataTable1.forEach(i => {
        if (i.validFrom <= this.date) {
          total += i.pointRemains;
        }
      });
      return total;
    },
    clientId() {
      return this.getBasicInfors?.clientId;
    },
    isSp() {
      return this.getBasicInfors?.productTypeId === 2;
    },
  },

  methods: {
    ...mapActions([
      'contractPointSummaryList',
      'contractPointMovementList',
      'basicInformationList',
      'pwContractUsageList',
      'UpdatepwContractUsageList',
    ]),
    ...mapMutations([
      'setContractPointSummary',
      'setContractPointMovementList',
      'setValidCarry',
      'setpwContractUsageList',
      'setAlertSuccess',
      'setDialogSuccess',
    ]),
    redirectPMS() {
      const url = `${process.env.VUE_APP_URL_PMS}/reservation/calendar?id=${this.getBasicInfors.clientId}&contractId=${this.$route.params.id}&fromPage=crm`;
      window.open(url);
    },
    redirectPMS_PW(id) {
      const url = `${process.env.VUE_APP_URL_PMS}/reservation/contract-detail?id=${id}&fromPage=crm`;
      window.open(url);
    },
    formatDate(value) {
      return moment(value).format('yyyy年MM月DD日');
    },
    formatDateChar(date) {
      return moment(date).format('YYYY/MM/DD');
    },

    getNumOfPage(pagination) {
      this.numLength = pagination.itemsLength;
      this.itemStart = pagination.pageStart + 1;
      this.itemStop = pagination.pageStop;
    },

    getYear(value) {
      this.year = value;
    },
    getPoint(v) {
      this.pointRemains = v;
    },
    forward(item) {
      const carryData = {
        points: item.pointRemains,
        validTo: item.validTo,
      };
      this.setValidCarry(carryData);
      this.dialog = 7;
    },
    getDateTable() {
      this.dataTable1 = this.getContractPointSummary.filter(
        item => item.validTo >= this.date,
      );
      this.dataTable2 = this.getContractPointSummary.filter(
        item => item.validTo < this.date,
      );
    },
    filterYear(item, arr, type) {
      return arr
        .filter(el => el.year === item && this.checkDateExpired(el.expirationDate) === (type !== 0))
        .slice(0, 1) // see TO2020-750 PW表示について for why we need this.
    },
    filterYearExpired(item, arr) {
      return arr.filter(el => el.yearExpired === item);
    },
    initFacilities() {
      this.ArrYear = [];
      this.facilitiesPWContract = [];
      this.facilitiesExpiredPWContract = [];
      this.getArrYear();
    },
    checkDateTime(a, b) {
      if (a - b >= 0) {
        return true;
      } else {
        return false;
      }
    },
    getDatafacilitiesPWContractByYear(val, idx) {
      const data = this.pwContractUsageApi.filter(v => v.validity.year === val);
      if (data.length > 0) {
        this.arrayListMaxDateByYear = [];
        this.week = this.contractApi.mwPwWeekNumber;
        this.pointUnitUrice = '¥' + this.contractApi.pointPrice;
        this.rightsFacility = this.contractApi.mwPwRoomType.facility.name;
        this.yearPW = val;
        data.forEach(element => {
          let maxDateByYear = null;
          this.dateOfOccurrence = element.validity.validFrom + ' 00:01';
          this.deadlineStart = element.validity.validFrom;
          this.expirationDate = element.validity.validThru;
          const item = {
            idx: idx,
            year: this.yearPW,
            rightsFacility: this.rightsFacility,
            week: this.week,
            dateOfOccurrence: this.dateOfOccurrence
              ? this.dateOfOccurrence.replace(/-/g, '/')
              : 'なし',
            deadlineStart: this.deadlineStart
              ? this.deadlineStart.replace(/-/g, '/')
              : 'なし',
            expirationDate: this.expirationDate
              ? this.expirationDate.replace(/-/g, '/')
              : 'なし',
            pointUnitUrice: this.pointUnitUrice,
          };
          this.facilitiesPWContract.push(item);
          element.bookingGroup.bookings.forEach(v => {
            if (v.computed.status === 'キャンセル') {
              this.show = false;
            } else {
              this.show = true;
            }
            if (
              v.computed.status === '利確待ち' ||
              v.computed.status === '利確済み'
            ) {
              if (
                this.checkDateTime(new Date(this.expirationDate), new Date())
              ) {
                maxDateByYear = this.expirationDate;
              } else {
                if (
                  v.checkOutDate &&
                  this.checkDateTime(new Date(v.checkOutDate), new Date())
                ) {
                  maxDateByYear = v.checkOutDate;
                }
              }
            }
            const itemExpired = {
              idx: idx,
              show: this.show,
              yearExpired: val,
              idExpired: v.id,
              status: v.computed.status,
              reservationID: v.id,
              institution: v.room.roomType.facility.name,
              accommodationStartDate: v.checkInDate
                ? v.checkInDate.replace(/-/g, '/')
                : 'なし',
              lastDay: v.checkOutDate
                ? v.checkOutDate.replace(/-/g, '/')
                : 'なし',
              roomType: v.room.roomType.name,
              UsageConfirmation: v.stayConfirmedAt,
              SPTotal: v.spAccommodationPoint,
              ReservationConfirmationPMS: v.id,
            };
            this.facilitiesExpiredPWContract.push(itemExpired);
            const itemMaxDate = {
              year: val,
              maxDateByYear: maxDateByYear,
            };
            this.arrayListMaxDateByYear.push(itemMaxDate);
          });
        });
        this.arrayListMaxDateByYear.forEach(el => {
          if (el.maxDateByYear) {
            this.ArrYear.push(el.year);
          } else {
            this.ArrYearCheckOut.push(el.year);
          }
        });
        // remove duplicate year
        this.ArrYear = this.removeDuplicateYear(this.ArrYear);
        this.ArrYearCheckOut = this.removeDuplicateYear(this.ArrYearCheckOut);
      }
    },
    removeDuplicateYear(arr) {
      const uniqueRoomIds = arr.filter(function(elem, index, self) {
        return index === self.indexOf(elem);
      });
      return uniqueRoomIds;
    },
    DateNowCus() {
      const d =
        new Date().getDate() < 10
          ? '0' + new Date().getDate()
          : new Date().getDate();
      const m =
        new Date().getMonth() < 10
          ? '0' + new Date().getMonth()
          : new Date().getMonth();
      const y = new Date().getFullYear();
      return y + '/' + m + '/' + d;
    },
    checkDateExpired(d) {
      if (new Date() - new Date(d) > 0) {
        return true;
      } else {
        return false;
      }
    },
    getArrYear() {
      this.contractApi = this.getpwContractUsageList.contract;
      this.pwContractUsageApi = this.getpwContractUsageList.pwContractUsage.rights;
      this.facilitiName = this.contractApi.mwPwRoomType.facility.name;
      this.ArrYear = [];
      this.ArrYearCheckOut = [];
      this.pwContractUsageApi.forEach(element => {
        // this.getDatafacilitiesPWContractByYear(element.validity.year,idx)
        this.ArrYearfillter.push(element.validity.year);
      });
      const uniqueRoomIds = this.ArrYearfillter.filter(function(
        elem,
        index,
        self,
      ) {
        return index === self.indexOf(elem);
      });
      uniqueRoomIds.forEach((element, idx) => {
        this.getDatafacilitiesPWContractByYear(element, idx);
      });
    },
    ChangeUseUnconfirmed(item, type) {
      if (type === 0) {
        this.isUdo = false;
        this.selectedYes = 1;
      } else {
        this.isUdo = true;
        this.selectedYes = 2;
      }
      this.statusSelect = true;
      this.bookingId = item;
      this.dialog = 8;
    },
    changeSelected(val) {
      this.statusSelect = false;
      this.dialog = -1;
      const data = {
        id: this.bookingId,
        undo: this.isUdo,
      };
      this.UpdatepwContractUsageList(data).then(() => {
        // this.setAlertSuccess(
        //   'アクティビティのスケジュールを正常に編集する',
        // );
        this.setDialogSuccess(true);
      });
    },
    CancelAndConvertPoints(ev) {
      this.CancelAndConvertPointsID = ev.idExpired;
      this.open = !this.open;
      this.Spoint = ev.SPTotal;
      this.dialog = 9;
    },
    reload() {
      this.contractPointSummaryList();
      this.contractPointMovementList();
    }
  },
  components: {
    ContractDialog,
    PointAffiliatedFacilityReservation,
    AdditionalPurchase,
    Exception,
    contractDetail,
    Breakdown,
    PointExchangeOrderDialog,
    CarryForward,
    CalculateBookingCancelPenalty,
    SimpleTable,
    Table,
  },
};
</script>

<style lang="scss" scoped>
.table-primary::v-deep tbody > tr:hover {
  background: #ffffff !important;
}

.text-title-component {
  font-size: 20px;
  color: #004f8b;
  font-weight: bold;
}
.text-title-span {
  font-size: 28px;
  color: #004f8b;
  font-weight: 500;
}
.text-center {
  .v-btn--icon {
    background-color: #3083dc !important;
  }
}
.t-btn--prm,
.t-btn--red-dark {
  font-size: 14px !important;
  height: 32px !important;
}
.table1 {
  .v-data-table__wrapper {
    table {
      thead {
        tr {
          th:nth-child(1) {
            width: 90px !important;
          }
        }
        tr {
          th:nth-child(2) {
            width: 150px !important;
          }
        }
        tr {
          th:nth-child(3) {
            max-width: 70% !important;
          }
        }
        tr {
          th:nth-child(4) {
            width: 160px !important;
          }
        }
        tr {
          th:nth-child(5) {
            width: 150px !important;
          }
        }
      }
      tbody {
        tr {
          td:nth-child(5) {
            text-align: center !important;
          }
        }
      }
    }
  }
}
.table2 {
  .v-data-table__wrapper {
    table {
      thead {
        tr {
          th:nth-child(1) {
            width: 90px !important;
          }
        }
        tr {
          th:nth-child(2) {
            width: 150px !important;
          }
        }
        tr {
          th:nth-child(3) {
            max-width: 70% !important;
          }
        }

        tr {
          th:nth-child(4) {
            width: 150px !important;
          }
        }
      }
      tbody {
        tr {
          td:nth-child(4) {
            text-align: center !important;
          }
        }
      }
    }
  }
}
::v-deep {
  .v-btn--disabled {
    background-color: #e0e0e0 !important;
  }

  .v-card {
    .v-size--small {
      .v-btn__content {
        font-size: 14px !important;
        font-weight: 500;
      }
    }
    .v-btn:not(.v-btn--round).v-size--small {
      height: 32px !important;
    }
  }
  .btn-crm-primary {
    .v-btn__content {
      font-size: 12px !important;
      color: #1873d9;
    }
  }
}
.v-data-table::v-deep {
  th,
  .v-select__selection {
    font-size: 16px !important;
    color: #424242 !important;
    font-weight: 400 !important;
  }
  tbody {
    tr {
      td {
        color: #424242 !important;
        font-weight: 400 !important;
        font-size: 16px !important;
      }
    }
  }
  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 12px !important;
        }
      }
    }
    .v-icon__svg {
      color: #333333 !important;
    }
  }
  .v-data-footer__pagination {
    display: none !important;
  }
}
.size-component {
  width: 100%;
}

.btn-icon {
  background-color: #3083dc;
}

.btn-add {
  min-width: 92px;
  height: 24px;
  color: #1873d9 !important;
  font-size: 12px;
  border: #1873d9 1px solid !important;
}

.text-diff-part {
  color: #3083dc;
}

.custom-dialog {
  label {
    width: 120px !important;
    flex-shrink: 0;
    text-align: right;
  }
}
.w-75 {
  width: 75%;
}
.span-custom-pw {
  height: 10px;
}
.pw-custom {
  width: 99px;
  height: 27px;
  background: #ffc3b1 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  text-align: center;
  color: #ffffff;
}
.PMS-btn {
  width: 99px;
  height: 27px;
}
.v-btn__content {
  text-align: left;
  font: normal normal 600 10px/15px Noto Sans;
  letter-spacing: 0px;
  color: #1873d9 !important;
  opacity: 1;
}
.PMS-btn-Cancel {
  width: 176px;
  height: 32px;
  background: #db3069 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  opacity: 1;
}
.table-primary::v-deep tbody > tr:hover {
  background: #ffffff !important;
}
.text-title-component {
  font-size: 20px;
  color: #004f8b;
  font-weight: bold;
}
.text-title-span {
  font-size: 28px;
  color: #004f8b;
  font-weight: 500;
}
.text-center {
  .v-btn--icon {
    background-color: #3083dc !important;
  }
}
.t-btn--prm,
.t-btn--red-dark {
  font-size: 14px !important;
  height: 32px !important;
}
::v-deep {
  .v-card {
    .v-size--small {
      .v-btn__content {
        font-size: 14px !important;
        font-weight: 500;
      }
    }
    .v-btn:not(.v-btn--round).v-size--small {
      height: 32px !important;
    }
  }
  .btn-crm-primary {
    .v-btn__content {
      font-size: 12px !important;
      color: #1873d9;
    }
  }
}
.v-data-table::v-deep {
  th,
  .v-select__selection {
    font-size: 16px !important;
    color: #424242 !important;
    font-weight: 400 !important;
  }
  tbody {
    tr {
      td {
        color: #424242 !important;
        font-weight: 400 !important;
        font-size: 16px !important;
      }
    }
  }
  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 12px !important;
        }
      }
    }
    .v-icon__svg {
      color: #333333 !important;
    }
  }
  .v-data-footer__pagination {
    display: none !important;
  }
}
.custom-table1::v-deep {
  table {
    thead {
      tr {
        th {
          border: none;
          border-bottom: none !important;
        }
      }
    }
    tbody {
      tr {
        td {
          border: none !important;
        }
      }
    }
  }
}
.size-component {
  width: 100%;
}

.btn-icon {
  background-color: #3083dc;
}

.btn-add {
  min-width: 92px;
  height: 24px;
  color: #1873d9 !important;
  font-size: 12px;
  border: #1873d9 1px solid !important;
}

.text-diff-part {
  color: #3083dc;
}
.pw-summary-table {
  box-shadow: none !important;
  max-width: 1100px;
}
</style>
