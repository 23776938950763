<template>
  <span v-if="$apollo.loading"><v-progress-circular indeterminate/></span>
  <span v-else-if="!calculated">-</span>
  <tvos-jpy v-else :value="calculated.totalPrice" />
</template>

<script>
import gql from 'graphql-tag'

export default {
  props: {
    price: Number,
    taxType: {
      type: String,
      default: "Normal" // either Normal / Lowered / Exempt
    },
    taxIncluded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      calculated: null
    }
  },
  apollo: {
    calculated: {
      query: gql`query ($price: Int!, $taxType: TaxType!, $taxIncluded: Boolean) {
        salesTax(
          price: $price
          salesTaxType: $taxType
          taxIncluded: $taxIncluded
        ) {
          totalPrice
          salesTax
          priceWithoutSalesTax
        }
      }`,
      variables() {
        return {
          price: this.price ?? 0,
          taxType: this.taxType,
          taxIncluded: this.taxIncluded
        }
      },
      skip () {
        return typeof this.price !== 'number' || isNaN(this.price)
      },
      update: data => data.salesTax
    }
  }
}
</script>