<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeDialog">
      <v-form ref="form">
        <h1 style="color: #212121">{{ $route.params.type }}追加購入</h1>
        <p style="color: #666666; font-size: 18px" class="mt-3 textUnitPrice">
          {{this.textPrice}}
        </p>
        <div style="width: 50% !important">
          <v-select
            label="分類"
            v-model="classificationSelected"
            @change="changeDataReasonId($event)"
            :items="Classification"
            item-value="id"
            item-text="name"
            style="font-size: 12px!important"
            :rules="rules.Classification"
          ></v-select>
        </div>
        <div style="width: 50% !important">
          <tvos-int-input
            jpy
            label="単価"
            v-model="UnitPrice"
            :rules="[$rules.required]"
          />
        </div>
        <div style="width: 50% !important">
          <tvos-int-input
            :label="labelField"
            v-model="PointName"
            :rules="[$rules.required]"
          />
        </div>
        <div style="width: 50% !important">
          <v-text-field
            label="備考"
            v-model="remarks"
            :rules="[$rules.required, $rules.checkLenghInput(255)]"
          ></v-text-field>
        </div>
        <div class="d-flex justify-end">
          <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
            {{ $t('task_29786.close') }}
          </v-btn>
          <v-btn class="t-btn--prm" to @click="save"> 保存 </v-btn>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
export default {
  name: 'AdditionalPurchase',
  data() {
    return {
      labelField: this.$route.params.type + '数',
      price: '',
      UnitPrice: null,
      textPrice:'',
      remarks: null,
      PointName: null,
      Classification: [
        {
          id: 'Purchased',
          name: '購入',
        },
        {
          id: 'SignupBonus',
          name: '契約特典',
        },
        {
          id: 'Campaign',
          name: '広告施策',
        },
        {
          id: 'Other',
          name: 'その他',
        },
      ],
      classificationSelected: null,
      rules: {
        Classification: [(v) => !!v || this.$t('rules.nameIsRequired')],
      },
    };
  },
  props: {
    visible: Boolean,
    year: Number,
    contractId: Number,
  },
  created () {
    if (this.getBasicInfors.additionalPointPrice == null) {
      this.UnitPrice = 0;
      this.textPrice = "契約時追加"+this.$router.currentRoute.params.type+"単価 記入なし";
    } else {
      this.textPrice = "契約時追加"+this.$router.currentRoute.params.type+"単価 : ¥" + this.getBasicInfors.additionalPointPrice;
      this.UnitPrice = this.getBasicInfors.additionalPointPrice;
    }
  },
  computed: {
    ...mapGetters(['getBasicInfors']),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapActions(['actionAddNewPurchasePoints']),
    ...mapMutations([
      'setAddNewPurchasePoints',
      // Alert
      'setAlertSuccess',
      'closeDialog',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),
    async save() {
      if (this.$refs.form.validate()) {
        const addNewPurchasePoints = {
          contractId: this.contractId,
          year: this.year,
          reason: this.classificationSelected,
          pointPriceOverride: this.UnitPrice,
          points: this.PointName,
          remarks: this.remarks,
        };
        this.setAddNewPurchasePoints({
          addNewPurchasePoints: addNewPurchasePoints,
        });
        await this.actionAddNewPurchasePoints();
        this.closeDialog();
      }
    },
    closeDialog() {
      this.remarks = null;
      this.PointName = null;
      this.classificationSelected = null;
      if (!this.$refs.form.validate()) {
        this.$refs.form.resetValidation();
      }
      this.$emit('close');
    },
    changeDataReasonId(value) {
      this.classificationSelected = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application .v-input *{
  font-size: 12px !important;
}
.textUnitPrice {
  font-size: 15px;
  color: gray;
}
::v-deep {
  .selectMembership .v-select__selection {
    color: red;
  }
  .v-dialog {
    width: 400px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 12px;
    .v-label {
      font-size: 12px;
    }
  }
  .v-select {
    &__slot {
      .v-label {
        &--active {
          font-size: 12px !important;
          color: #3083dc;
        }
      }
      .primary--text {
        color: #3083dc !important;
      }
    }
    &__selection--comma{
      font-size: 12px !important;
    }
  }
  .theme--light.v-label {
    color: #000000;
    .primary--text {
      color: #3083dc !important;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
</style>