<template>
  <div>
    <v-dialog v-model="dialog">
      <v-form ref="form">
        <h1>例外{{ $route.params.type }}利用・取り消し</h1>
        <div class="mt-5">
          <h6>対象日</h6>
          <date-picker-input v-model="date" :rules="[$rules.required]" />
        </div>
        <div class="mt-5">
          <h6>利用{{ $route.params.type }}数</h6>
          <v-text-field
            single-line
            v-model="points"
            :rules="[$rules.required, $rules.checkLenghInput(255)]"
            type="number"
          ></v-text-field>
        </div>
        <div>
          <h6>{{ $route.params.type }}利用履歴向け備考</h6>
          <v-text-field
            v-model="reason"
            single-line
            :rules="[$rules.required, $rules.checkLenghInput(255)]"
          ></v-text-field>
        </div>
        <div class="d-flex justify-end">
          <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
            {{ $t('task_29786.close') }}
          </v-btn>
          <v-btn class="t-btn--prm" to @click="save"> 保存 </v-btn>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { getISODate } from '@/utils/dateUtil'
import DatePickerInput from '@/components/Input/DatePickerInput.vue';

export default {
  components: { DatePickerInput },
  name: 'Exception',
  data() {
    return {
      NumberOfUsage: '',
      points: '',
      reason: '',
      exceptionalReason: '',
      date: null
    };
  },
  props: {
    visible: Boolean,
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler() {
        this.date = getISODate(new Date())
      }
    }
  },
  methods: {
    ...mapActions(['subtractCurrentContractPointExc']),
    async save() {
      if (this.$refs.form.validate()) {
        const exceptionData = {
          contractId: parseInt(this.$router.currentRoute.params.id),
          points: parseInt(this.points),
          reason: this.reason,
          consumeDate: this.date,
        };
        await this.subtractCurrentContractPointExc(exceptionData);
        this.$emit('completed')
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$refs.form.reset();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  color: #212121;
}
h6 {
  font-size: 12px;
  font-weight: 600;
  color: #3083dc;
}
::v-deep {
  .selectMembership .v-select__selection {
    color: red;
  }
  .v-dialog {
    width: 420px;
    background-color: white;
    padding: 20px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-text-field {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
}
</style>