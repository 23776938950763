<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeDialog">
      <v-form ref="form">
        <h1>繰越手数料請求登録</h1>

        <v-row class="mt-3">
          <v-col cols="5">
            <h5 class="float-right">繰越料金単価(税抜)</h5>
          </v-col>
          <v-col>
            <h5>{{ defaultFee }}円</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <h5 class="float-right">繰越{{ $route.params.type }}数</h5>
          </v-col>
          <v-col>
            <h5>{{ quantity }}</h5>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5 my-auto">
            <h5 class="float-right">請求金額</h5>
          </v-col>
          <v-col>
            <tvos-int-input
              v-model="pay1"
              :rules="[$rules.required]"
              jpy
              class="pb-1"
              label="繰越手数料"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <h5 class="float-right">請求金額(税込)</h5>
          </v-col>
          <v-col>
            <h5>
              <price-with-sales-tax :price="pay1" />
            </h5>
          </v-col>
        </v-row>
        <div class="d-flex justify-end mt-11">
          <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
            {{ $t('task_29786.close') }}
          </v-btn>
          <v-btn class="t-btn--prm" to @click="save" :loading="saving"> 請求登録して完了 </v-btn>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import { mapMutations, mapActions } from 'vuex';
import PriceWithSalesTax from '../../../../../components/shared/PriceWithSalesTax.vue';

export default {
  components: { PriceWithSalesTax },
  name: 'Carry-overService',
  data() {
    return {
      pay1: 0,
      carryOverData: {},
      totalFee: null,
      // totalFeeOverride: null,
      lableSe: this.$route.params.type + '数',
      remarks: null,
      rules: {
        // remarks: [
        //   (v) => CheckLenghInput(v, 255) || this.$t('rules.maximumNCharacter', { value: 255 }),
        // ],
        checkFloat:[
          (v) => this.func_checkFloat(v) || '不正な数字です。',
        ]
      },
      saving: false,
      defaultFee: null,
    };
  },
  props: {
    visibleChange: Boolean,
    visible: Boolean,
    year: Number,
    contractId: Number,
    quantity: Number,
  },

  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch:{
    quantity(){
      this.pay1 = this.quantity * this.defaultFee
    },

    pay1(newVal){
      this.$refs.form?.resetValidation()
    }
    
  },
  methods: {
    ...mapActions(['advancePointsCarryOver']),
    ...mapMutations([
      // Alert
      'setAlertSuccess',
      'closeDialog',
    ]),
    async save() {
      if (this.$refs.form.validate()) {
        this.saving = true
        try {
          const carryOverData = {
            contractId: parseInt(this.$router.currentRoute.params.id),
            year: parseInt(this.year),
            points: this.quantity,
            remarks: this.remarks,
            // totalFee: this.quantity * 300*1.1,
            totalFee: this.pay1 
          };
          await this.advancePointsCarryOver(carryOverData);
          this.closeDialog();
        } finally {
          this.saving = false
        }
      }
    },

    func_checkFloat(v){
      if(parseFloat(v.slice(1)) % 1 === 0){
        return true
      }else{
        return false
      }
    },

    closeDialog() {
      this.$refs.form.reset();
      this.$emit('close');
      this.$emit('reset');
    },
  },
  apollo: {
    defaultFee: {
      query: gql`query getDefaultFeeOfRollOver {
        defaultRolloverFee {
          priceWithoutSalesTax
        }
      }`,
      update: data => data.defaultRolloverFee.priceWithoutSalesTax
    }
  }
};
</script>

<style lang="scss" scoped>
.textUnitPrice {
  font-size: 15px;
  color: gray;
}
h1 {
  color: #212121;
  font-size: 28px;
  font-weight: bold;
}
h5 {
  font-size: 18px;
  color: #666666;
  font-weight: 400;
}
.row + .row {
  margin-top: 0 !important;
}
.v-btn {
  height: 32px !important;
}
.t-btn--red-dark {
  width: 80px !important;
}

::v-deep {
  .selectMembership .v-select__selection {
    color: red;
  }
  .v-dialog {
    width: 450px;
    height: 380px;
    background-color: white;
    padding: 20px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 12px;
      color: #666666 !important;
      &--active {
        color: #3083dc !important;
      }
    }
    .theme--light.v-input input {
      font-size: 18px !important;
      color: #666666 !important;
    }
  }
  .v-input {
    max-width: 90% !important;
  }
  .v-select {
    &__slot {
      .v-label {
        color: #666666 !important;
        &--active {
          font-size: 12px;
          color: #3083dc;
        }
      }
      .primary--text {
        color: #3083dc !important;
      }
    }
    .v-input {
      &__slot {
        color: #3083dc !important;
        caret-color: #3083dc !important;
      }
    }
  }
  .theme--light.v-label {
    color: #000000;
    .primary--text {
      color: #3083dc !important;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-icon__svg {
    color: #333 !important;
  }
  .v-select__selection--comma {
    font-size: 18px !important;
    color: #666666 !important;
  }
}
</style>
