<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        type="text"
        autocomplete="off"
        :value="value"
        @input="emitInput"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="value"
      @input="emitInput"
      :locale="$i18n.locale"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: String,
    label: String
  },
  data () {
    return {
      menu: false
    }
  },
  methods: {
    emitInput(v) {
      this.$emit('input', v)
      this.menu = false
    }
  }
}
</script>